import React, { Component, PureComponent } from "react";
// import logo from "./logo.svg";
import "./App.scss";

import posed, { PoseGroup } from "react-pose";
import styled from "styled-components";
import { tween } from "popmotion";
const logo = require("./assets/appcraft-events-logo-white.png");

class Icon extends PureComponent {
  render() {
    const { name, ...props } = this.props;
    return <i className={`fa fa-${name}`} {...props} />;
  }
}

const gridSize = { w: 5, h: 4 };

function round(v) {
  return v;
}

const Cell = posed.div({
  fullscreen: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    transform: "scale(1)",
    transition: tween,
    applyAtStart: { zIndex: 100 }
  },
  idle: {
    position: "fixed",
    width: props => `calc(${round((props.w * 100) / gridSize.w - 0.5)}vw)`,
    height: props => `calc(${round((props.h * 100) / gridSize.h)}vh - 0.5vw)`,
    left: props => `calc(0.25vw + ${round((props.x * 100) / gridSize.w)}vw)`,
    top: props => `calc(0.25vw + ${round((props.y * 100) / gridSize.h)}vh)`,
    transform: "scale(1)",
    transition: tween,
    zIndex: 0
  },
  hide: {
    position: "fixed",
    width: "50vw",
    height: "50vh",
    top: props => props.top,
    left: props => props.left,
    transform: "scale(0)",
    transition: tween
  }
});

// const TitleIcon = posed.div({
//   fullscreen: {
//     position: "absolute",
//     width: "15vh",
//     height: "15vh",
//     top: "0.5vh",
//     left: "0.5vh",
//     transition: tween,
//   },
//   idle: {
//     position: "absolute",
//     width: "50vw",
//     height: "50vh",
//     top: "10vh",
//     left: "0vh",
//     transition: tween,
//   }
// });

// const TitleText = posed.div({
//   fullscreen: {
//     position: "absolute",
//     width: "15vh",
//     height: "15vh",
//     top: "0.5vh",
//     left: "0.5vh",
//     transition: tween,
//   },
//   idle: {
//     position: "absolute",
//     width: "50vw",
//     height: "50vh",
//     top: "10vh",
//     left: "0vh",
//     transition: tween,
//   }
// });

class GridCell extends Component {
  handleClick = () => {
    const { name, selected, onSelect } = this.props;
    if (!onSelect) return;
    if (selected) {
      if (selected === name) onSelect(null);
      return;
    }
    onSelect(name);
  };

  render() {
    const { name, selected, ...props } = this.props;
    let pose = "idle";
    if (selected) {
      pose = selected === name ? "fullscreen" : "idle"; //"hide";
    }
    return (
      <Cell
        className={`square square--${name} square--${pose}`}
        {...props}
        pose={pose}
        onClick={pose === "idle" ? this.handleClick : null}
      />
    );
  }
}

class Title extends PureComponent {
  handleBack = () => {
    this.props.onSelect(null);
  };

  render() {
    const { title, icon, image, showImage = true, style = {} } = this.props;
    const { containerStyle, imageStyle, pictoStyle } = style;
    // const pose = this.props.pose === "fullscreen" ? "fullscreen" : "idle";
    return (
      <div className="square__content" style={containerStyle}>
        <div
          className="square__image"
          style={{ backgroundImage: showImage ? `url(${image})` : undefined, ...imageStyle }}
        />
        <div className="header">
          <div className="header__icon" onClick={this.handleBack}>
            <Icon name="arrow-left" />
          </div>
          <div className="header__title">{title}</div>
          {icon && <img className="header__picto" src={icon} alt="icon" style={pictoStyle} />}
        </div>
      </div>
    );
  }
}

class HBox extends PureComponent {
  render() {
    const { children } = this.props;
    return <div className="box--horizontal">{children}</div>;
  }
}

class List extends PureComponent {
  render() {
    const { items } = this.props;
    return (
      <ul className="list--animated" style={{}}>
        {items.map(({ text, icon }, index) => (
          <li key={index} style={{ listStyleType: "none", display: "flex", alignItems: "center" }}>
            {icon && <img src={icon} style={{ height: "auto", width: "4vw", marginRight: 20 }} />} {text}
          </li>
        ))}
      </ul>
    );
  }
}

class Video extends PureComponent {
  render() {
    const { name, fullscreen, visible = true, children } = this.props;
    if (!visible) return false;
    return (
      <div className={"video " + (fullscreen ? "video--fullscreen" : "")}>
        <video autoPlay loop>
          <source src={`/videos/${name}.mp4`} type="video/mp4" />
        </video>
        {children}
      </div>
    );
  }
}

class SmartphoneVideo extends PureComponent {
  render() {
    const { name, visible = true, children, extension = "mp4", style = {} } = this.props;
    if (!visible) return false;
    return (
      <div className={"video video--smartphone"} style={style}>
        <video className="shadow" autoPlay loop>
          <source src={`/videos/${name}.MP4`} type="video/mp4" />
        </video>
        {children}
      </div>
    );
  }
}

class Gallery extends PureComponent {
  state = {
    index: 0
  };

  showPrev = () => {
    const { index } = this.state;
    if (index === 0) this.setState({ index: this.props.images.length - 1 });
    else this.setState({ index: index - 1 });
  };

  showNext = () => {
    const { index } = this.state;
    const { images } = this.props;
    if (index === images.length - 1) this.setState({ index: 0 });
    else this.setState({ index: index + 1 });
  };

  render() {
    const { images, style = {} } = this.props;
    const { index } = this.state;
    return (
      <div className="image image--gallery shadow" style={{ backgroundImage: `url(${images[index]})`, ...style }}>
        <Icon name="chevron-left" onClick={this.showPrev} />
        <Icon name="chevron-right" onClick={this.showNext} />
      </div>
    );
  }
}

class BigImage extends PureComponent {
  render() {
    const { children, image } = this.props;
    return (
      <div className="image image--big shadow" style={{ backgroundImage: `url(${image})` }}>
        {children}
      </div>
    );
  }
}

class MobileImage extends PureComponent {
  render() {
    const { children, image } = this.props;
    return (
      <div className="image image--mobile shadow" style={{ backgroundImage: `url(${image})` }}>
        {children}
      </div>
    );
  }
}

class MobileImages2 extends PureComponent {
  render() {
    const { images } = this.props;
    return (
      <div className="images images--mobile">
        {images.map(({ image, text }) => (
          <div>
            <div
              key={image}
              className="image image--mobile shadow"
              style={{ backgroundImage: `url(${image})`, width: "28vh", height: "61vh" }}
            ></div>

            <p style={{ fontSize: "3vh", fontWeight: "bold", textAlign: "center" }}>{text}</p>
          </div>
        ))}
      </div>
    );
  }
}

class MobileImages extends PureComponent {
  render() {
    const { images } = this.props;
    return (
      <div className="images images--mobile">
        {images.map(image => (
          <div key={image} className="image image--mobile shadow" style={{ backgroundImage: `url(${image})` }} />
        ))}
      </div>
    );
  }
}

class Caption extends PureComponent {
  render() {
    const { text } = this.props;
    return <div className="caption">{text}</div>;
  }
}
class Subtitle extends PureComponent {
  render() {
    const { text, style } = this.props;
    return (
      <div className="subtitle" style={style}>
        {text}
      </div>
    );
  }
}
class TopTitle extends PureComponent {
  render() {
    const { text } = this.props;
    return <div className="toptitle">{text}</div>;
  }
}

class Tags extends PureComponent {
  render() {
    const { tags, style = {} } = this.props;
    const { containerStyle = {}, tagStyle = {} } = style;
    return (
      <div className="tags" style={containerStyle}>
        {tags.map(({ tag, icon }, index) => (
          <div key={index} className="tag" style={tagStyle}>
            {icon && <img src={icon} style={{ height: 30, width: 30, marginRight: 20 }} />} {tag}
          </div>
        ))}
      </div>
    );
  }
}

class SubSections extends PureComponent {
  state = {
    selected: null
  };

  handleSelect = selected => this.setState({ selected });

  render() {
    const { sections, style = {} } = this.props;
    const { selected } = this.state;
    const { containerStyle = {}, titleStyle = {}, subtitleStyle = {} } = style;
    return (
      <div className="sections">
        {sections.map((section, index) => (
          <div className="section shadow" style={containerStyle} key={index} onClick={() => this.handleSelect(section)}>
            <div className="section__image" style={{ backgroundImage: `url(${section.image})` }}>
              <div className="overlay" />
            </div>
            <div className="section__title" style={titleStyle}>
              {section.name}
            </div>
            <div className="section__subtitle" style={subtitleStyle}>
              {section.pitch}
            </div>
          </div>
        ))}
        {selected && this.renderSelected(selected)}
      </div>
    );
  }

  renderSelected(section) {
    const { style = {} } = this.props;
    const { galleryStyle = {} } = style;
    return (
      <div className="modal">
        <div className="modal__back">
          <Icon name="arrow-left" onClick={() => this.handleSelect(null)} />
        </div>
        <div className="modal__image" style={{ backgroundImage: `url(${section.image})` }} />
        {section.video && <Video name={section.video} />}
        {section.fullImage && <BigImage image={section.fullImage} />}
        {section.gallery && <Gallery images={section.gallery} style={galleryStyle} />}
        {/* <div className="modal__title">{section.name}</div>
        <div className="modal__subtitle">{section.pitch}</div> */}
      </div>
    );
  }
}

class App extends Component {
  state = {
    selected: null
  };

  handleSelect = selected => {
    console.log("selected", selected);
    this.setState({ selected });
  };

  render() {
    const { selected } = this.state;
    return (
      <div className="fullscreen">
        Hello
        <div className="fullscreen">
          <GridCell name="registration" selected={selected} x={0} y={0} w={1} h={2} onSelect={this.handleSelect}>
            <Title
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #081f4d, #10367f)" },
                pictoStyle: { marginTop: 20 }
              }}
              icon="edit"
              title="Inscription"
              onSelect={this.handleSelect}
              image={require("./assets/registration.png")}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/w18ANeoMa8diH-WA14yr8qkTMKs_.png"
            />
            <SubSections
              sections={[
                {
                  name: "Site événementiel",
                  pitch: "Créez votre propre site dédié en quelques clics",
                  video: "site-editor",
                  image: require("./assets/SiteTotal.png")
                },
                {
                  name: "Formulaire",
                  pitch: "Concevez un formulaire qui s’adapte à chaque participant",
                  video: "form-viewer",
                  image: require("./assets/registration-form.png")
                },
                {
                  name: "Campagne de mailing",
                  pitch: "Prenez contact avec vos invités au bon moment",
                  image: require("./assets/registration-mails-header.png"),
                  gallery: [require("./assets/MailConfirmationTotal.png"), require("./assets/registration-mails.png")]
                },
                {
                  name: "Suivi des inscriptions",
                  pitch: "Gérez les anomalies - Monitorez vos envois",
                  image: require("./assets/registration-anomalies-header.png"),
                  gallery: [
                    require("./assets/registration-anomalies.png"),
                    require("./assets/DashboardInscriptions.png")
                  ]
                }
              ]}
            />
            {/* <List
            items={[
              "Créez un site vraiment unique en quelques clics",
              "Concevez des formulaires d'inscription dynamique",
              "Programmez vos mailings pour plus de tranquilité"
            ]}
          /> */}
          </GridCell>
          <GridCell name="checkin" selected={selected} x={1} y={1} w={1} h={1} left="50vw" onSelect={this.handleSelect}>
            <Title
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #b83a17, #f34b1c)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/PdeG+ZEu2r8El0ZinSYvYjfYmv8_.png"
              title="Checkin"
              onSelect={this.handleSelect}
              image={require("./assets/checkin-small.jpg")}
            />
            {selected === "checkin" && (
              <Gallery
                images={[require("./assets/EditeurQRCode.png"), require("./assets/ScanQRCode.png")]}
                style={{ width: "88vh", height: "57vh", left: "4vw", top: "10vw" }}
              />
            )}
            {/* <BigImage image={require("./assets/checkin-bo.png")} /> */}
            <MobileImage image={require("./assets/checkin-mobile2.jpg")} />
            <Subtitle text=" Fluidifiez vos émargements depuis n’importe quel device" />
          </GridCell>
          <GridCell name="organization" selected={selected} x={1} y={0} w={3} h={1} onSelect={this.handleSelect}>
            <Title
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #ff9838, #bd722d)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/LMAsTWiN17qwZhemyglZpV+zH7s_.png"
              title="Organisation"
              onSelect={this.handleSelect}
              image={require("./assets/organization.png")}
            />
            <Gallery
              images={[
                require("./assets/organization-bg.png"),
                require("./assets/organization-groups.png"),
                require("./assets/organization-agenda.png")
              ]}
              style={{ width: "90vh", height: "70vh", left: "4vw" }}
            />
            {/* <BigImage image={require("./assets/organization-bg.png")} /> */}
            <Subtitle text="Gagnez du temps avec un CRM qui s’adapte à vos événements" />
            <Tags
              tags={[
                {
                  tag: "Participants et groupes",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/ne0TJEcVOwaKacLHXLkKzlRmAkw_.png"
                },
                {
                  tag: "Hotels et rooming",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/2caCaMlb6bleD3+Xs8swx3GK9K8_.png"
                },
                {
                  tag: "Activités et ateliers",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/V-Ir3DkKUgKTMmTwHC1bjhifpSU_.png"
                },
                {
                  tag: "Inscriptions live",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/9W4AtebDkOhYpxR1W0cGJNPPcQQ_.png"
                },
                {
                  tag: "Agenda personnalisé",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/HYOkzvVqdBzXUoFVeJIq9Db0pgY_.png"
                }
              ]}
            />
          </GridCell>
          <GridCell name="app" selected={selected} x={2} y={1} w={2} h={1} onSelect={this.handleSelect}>
            <Title
              showImage={false}
              style={{
                // containerStyle: { background: "linear-gradient(to right, #216ebb, #23D0D2)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/jrajwjqfUo3n3nLevg-HaLLia1Y_.png"
              title="Application"
              onSelect={this.handleSelect}
              image={require("./assets/croft-imagelist.jpg")}
            />
            <TopTitle text="Offrez à vos participants une application mobile premium" />
            <List
              items={[
                {
                  text: "Fluide",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/0Nu1U3zv5NvKCaHkMkAm0Lj2J3c_.png"
                },
                {
                  text: "Native",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/Gl8N0anUTn10qKfDj0oJmTsFESg_.png"
                },
                {
                  text: "Intuitive",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/arqXNG6i3bwfV-xTvIasAle5Bn4_.png"
                },
                {
                  text: "Design",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/7yXUKVHDNS9wxBP+elzCXs888t4_.png"
                },
                {
                  text: "Customizable",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/YWDobz8ZEECHCP6B2vprryPGAew_.png"
                },
                {
                  text: "Individualisée",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/9WKrP4zltRx1lY-3IMGUAGAeONg_.png"
                }
              ]}
            />
            <SmartphoneVideo name="StarterpackAppMobile" className="shadow" visible={selected === "app"} />
          </GridCell>
          <GridCell name="networking" selected={selected} x={2} y={2} w={1} h={1} onSelect={this.handleSelect}>
            <Title
              style={{
                containerStyle: { background: "linear-gradient(to right, #10367f, #081f4d)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              title="Networking"
              onSelect={this.handleSelect}
              image={require("./assets/iphone-matchmaking.png")}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/37gFAsEXTOxDtXKJzHIIJwVjxaI_.png"
            />
            <Subtitle text="Suivi des rdv en temps réel" style={{ bottom: "22vh", left: "24vw" }} />
            <SmartphoneVideo
              name="apecvideo"
              className="shadow"
              visible={selected === "networking"}
              style={{ right: undefined, left: "2vw", top: "8vw" }}
            />
            {selected === "networking" && (
              <Gallery
                images={[require("./assets/apecdash1.png"), require("./assets/apecdash2.png")]}
                style={{ right: "2vw", width: "70vw", height: "57vh", left: "initial", top: "8vw" }}
              />
            )}
            <Tags
              style={{
                containerStyle: { left: "1vw", bottom: 0 },
                tagStyle: { color: "#0f367e", marginBottom: "0.5vw" }
              }}
              tags={[
                {
                  tag: "Prise de rendez-vous",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/KbsBTi8jUtWM+AT8nWBv9oppt7Q_.png"
                },
                {
                  tag: "Agenda mis à jour en temps réel",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/OQ0G7I0-9o3jBKVwYoy9XEjIyGo_.png"
                },
                {
                  tag: "Matching entre les participants",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/PmcUa+nB3y18be2WPwqQtAcjlK0_.png"
                },
                {
                  tag: "Inscription aux ateliers",
                  icon: "https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/FAx-Q8WaVL5gsiMtSi9jLGqjsvU_.png"
                }
              ]}
            />
          </GridCell>
          <GridCell name="interaction" selected={selected} x={0} y={2} w={2} h={1} onSelect={this.handleSelect}>
            <Title
              title="Interactivité"
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #bd722d, #ff9838)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/LY0Jc11-sun+GzOLpbhJm9rF1oU_.png"
              image="https://images.unsplash.com/photo-1501386761578-eac5c94b800a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=d3a9ac2b411e58b72bc6170216bccad2&auto=format&fit=crop&w=1350&q=80"
              onSelect={this.handleSelect}
            />
            <Subtitle text="Engagez vos participants à travers le mobile" />
            {/* <Video name="oec-short" fullscreen visible={selected === "interaction"}>
            <Caption text="Engagez les participants via une expérience digitale" />
          </Video> */}
            <MobileImages2
              images={[
                { image: require("./assets/Q&A.PNG"), text: "Q&A" },
                { image: require("./assets/Quiz.PNG"), text: "Quiz" },
                { image: require("./assets/SocialWallScreen.png"), text: "Social Wall" },
                { image: require("./assets/Messagerie.PNG"), text: "Messagerie" }
              ]}
            />
          </GridCell>
          <GridCell name="feedback" selected={selected} x={3} y={2} w={2} h={1} onSelect={this.handleSelect}>
            <Title
              title="Feedback"
              onSelect={this.handleSelect}
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #216ebb, #23D0D2)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/nLAE3QsHaOMPi6MhQJYe+5Me1ww_.png"
              image={require("./assets/feedback.png")}
            />
            {selected === "feedback" && (
              <Gallery
                images={[require("./assets/feedback-graphs.png"), require("./assets/feedback-list.png")]}
                style={{ width: "86vh", height: "57vh", left: "4vw", top: "10vw" }}
              />
            )}
            <MobileImage image={require("./assets/survey.png")} />
            <Subtitle text="Evaluez votre ROI grâce à des retours qualitatifs" />
          </GridCell>
          <GridCell name="stats" selected={selected} x={4} y={0} w={1} h={2} onSelect={this.handleSelect}>
            <Title
              title="Statistiques"
              onSelect={this.handleSelect}
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #b83a17, #f34b1c)" },
                pictoStyle: { marginTop: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/vszK4kisG9QN+HMQsWzw2BCYnXI_.png"
              image="https://www.appcraft.events/static/media/home-bg.586c95c6.jpg"
            />
            {selected === "stats" && (
              <Gallery
                images={[
                  require("./assets/stats-registration.png"),
                  require("./assets/stats-app.png"),
                  require("./assets/feedback-graphs.png")
                ]}
              />
            )}
            <Subtitle text="Monitorez votre événement en temps réel" />
          </GridCell>
          <GridCell name="live-display" selected={selected} x={4} y={3} w={1} h={1} onSelect={this.handleSelect}>
            <Title title="Live Display" onSelect={this.handleSelect} image={require("./assets/feedback.png")} />
            <List
              items={[
                "Affichez les photos en temps réel",
                "Informez les participants des réunions et actualités",
                "Laissez vos participants contribuer via le mur de décisions",
                "Surveillez l'humeur grâce au dashboard d'emoticons",
                "Animez votre événement via les quiz"
              ]}
            />
          </GridCell>
          <GridCell name="backoffice" selected={selected} x={0} y={3} w={1} h={1} onSelect={this.handleSelect}>
            <Title
              title="Cas clients"
              onSelect={this.handleSelect}
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #216ebb, #23D0D2)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              // image={require("./assets/feedback.png")}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/Wh0Fv0bWAeuzdPMFpe3ASQcQcok_.png"
            />
            <SubSections
              style={{
                containerStyle: { width: "23vw", margin: "2vw 1vw" },
                titleStyle: { fontSize: "1.8vw", display: "none" },
                subtitleStyle: { display: "none" },
                galleryStyle: { left: "3vw", right: "3vw" }
              }}
              sections={[
                {
                  image: require("./assets/givaudan.png"),
                  gallery: [
                    require("./assets/givaudan1.png"),
                    require("./assets/givaudan2.png"),
                    require("./assets/givaudan3.png"),
                    require("./assets/givaudan4.png")
                  ]
                },
                {
                  image: require("./assets/maif.png"),
                  gallery: [require("./assets/maif1.png"), require("./assets/maif2.png")]
                },
                {
                  image: require("./assets/bnp.png"),
                  gallery: [require("./assets/bnp1.png"), require("./assets/bnp2.png"), require("./assets/bnp3.png")]
                },
                {
                  image: require("./assets/sncf.png"),
                  gallery: [
                    require("./assets/sncf1.png"),
                    require("./assets/sncf2.png"),
                    require("./assets/sncf3.png"),
                    require("./assets/sncf4.png"),
                    require("./assets/sncf5.png"),
                    require("./assets/sncf6.png")
                  ]
                },
                {
                  name: "SAFRAN",
                  image: require("./assets/safran.png"),
                  gallery: [
                    require("./assets/safran1.png"),
                    require("./assets/safran2.png"),
                    require("./assets/safran3.png")
                  ]
                },
                {
                  name: "Orange",
                  image: require("./assets/orange.png"),
                  gallery: [
                    require("./assets/orange1.png"),
                    require("./assets/orange2.png"),
                    require("./assets/orange3.png"),
                    require("./assets/orange4.png"),
                    require("./assets/orange5.png"),
                    require("./assets/orange6.png")
                  ]
                },
                {
                  name: "Cushman & Wakefield",
                  image: require("./assets/cushman.png"),
                  gallery: [
                    require("./assets/cushwake1.png"),
                    require("./assets/cushwake2.png"),
                    require("./assets/cushwake3.png")
                  ]
                },
                {
                  name: "DECATHLON",
                  image: require("./assets/decathlon.png"),
                  gallery: [
                    require("./assets/decathlon1.png"),
                    require("./assets/decathlon2.png"),
                    require("./assets/decathlon3.png")
                  ]
                },
                {
                  name: "Leboncoin",
                  image: require("./assets/leboncoin.png"),
                  gallery: [
                    require("./assets/leboncoin1.png"),
                    require("./assets/leboncoin2.png"),
                    require("./assets/leboncoin3.png")
                  ]
                },
                {
                  name: "Nissan",
                  image: require("./assets/nissan.png"),
                  gallery: [
                    require("./assets/nissan1.png"),
                    require("./assets/nissan2.png"),
                    require("./assets/nissan3.png")
                  ]
                },
                {
                  name: "spie",
                  image: require("./assets/spie.png"),
                  gallery: [require("./assets/spie1.png")]
                },
                {
                  name: "Albus",
                  image: require("./assets/albus.png"),
                  gallery: [require("./assets/albus1.png")]
                }
              ]}
            />
            {/* <Gallery images={[require("./assets/sncf1.png"), require("./assets/apec1.png"), require("./assets/spie.png")]} /> */}

            {/* <Video name="home-drag" fullscreen visible={selected === "backoffice"}>
            <Caption text="Une seule compétence requise : savoir glisser-déposer des éléments" />
          </Video> */}
          </GridCell>
          <GridCell name="websites" selected={selected} x={1} y={3} w={1} h={1} onSelect={this.handleSelect}>
            <Title
              title="Sites web"
              onSelect={this.handleSelect}
              showImage={false}
              style={{
                containerStyle: { background: "linear-gradient(to right, #b83a17, #f34b1c)" },
                pictoStyle: { position: "absolute", bottom: 10 }
              }}
              icon="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/mlDws3N57pO9xwbjfjJjg1F2GOY_.png"
            />
            <Gallery
              style={{ left: "2vw", right: "2vw", height: "70vh" }}
              images={[
                require("./assets/arval.png"),
                require("./assets/nantes1.png"),
                require("./assets/nantes2.png"),
                require("./assets/nantes3.png"),
                require("./assets/sncfsite.png"),
                require("./assets/spiesite.png"),
                require("./assets/apec.png"),
                require("./assets/apec2.png"),
                require("./assets/petitdejsite2.png"),
                require("./assets/petitdejsite.png")
              ]}
            />
          </GridCell>
          <GridCell name="logo" selected={selected} x={2} y={3} w={3} h={1}>
            {/* <img src={logo} /> */}
            <Title image="https://app.appcraft.events/images/u3mXB8Wbe5jYHS/assets/i4a5ho1lsKcPJi0tnmx+iV7ogVc_.png" />
          </GridCell>
        </div>
      </div>
    );
  }
}

export default App;
